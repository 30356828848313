


































































































































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import CurrencyInput from "@/components/form/input/CurrencyInput.vue";
import RulesMixin from "@/mixins/RulesMixin";
import { ContactTypeId } from "@/entity/Contact";

@Component({
  components: {
    CurrencyInput,
  },
})
export default class ExpressionOfInterestFormFields extends Mixins(RulesMixin) {
  @Prop({ required: true, type: Number }) contactTypeId!: number;
  @Prop({ required: true, type: Boolean }) usedByAdmin!: boolean;
  @Prop({ required: true, type: Boolean }) disableEverything!: boolean;

  @PropSync("firstName", { default: "", type: String }) firstNameV!: string;
  @PropSync("name", { default: "", type: String }) nameV!: string;
  @PropSync("profileUrl", { default: "", type: String }) profileUrlV!: string;
  @PropSync("interestedNote", { default: "", type: String })
  interestedNoteV!: string;
  @PropSync("intentionsNote", { default: "", type: String })
  intentionsNoteV!: string;
  @PropSync("overtakeNote", { default: "", type: String })
  overtakeNoteV!: string;
  @PropSync("sellerRoleNote", { default: "", type: String })
  sellerRoleNoteV!: string;
  @PropSync("ownFunds", { default: "", type: [String, Number] })
  ownFundsV!: string;
  @PropSync("bankFinancedAcquisitionExperience", { default: "", type: String })
  bankFinancedAcquisitionExperienceV!: string;

  get showLastName(): boolean {
    return this.contactTypeId === ContactTypeId.contact;
  }

  get firstNameLabel(): string {
    if (!this.showLastName) {
      return this.$tc("companyName", 1);
    }

    return this.$tc("firstName", 1);
  }

  get acquisitionExperienceOptions() {
    return [
      {
        name: this.$t("acquisitionExperienceOptions.experienced").toString(),
        value: "EXPERIENCED",
      },
      {
        name: this.$t(
          "acquisitionExperienceOptions.limitedExperience"
        ).toString(),
        value: "LIMITED_EXPERIENCE",
      },
      {
        name: this.$t("acquisitionExperienceOptions.noExperience").toString(),
        value: "NO_EXPERIENCE",
      },
    ];
  }
}
