

































import { Component, Prop, Vue } from "vue-property-decorator";
import Tag from "@/entity/Tag";
import User, { UserRole } from "@/entity/User";
import { State } from "vuex-class";
import TagService from "@/services/TagService";

@Component
export default class HistoryTagsSelection extends Vue {
  @Prop({ default: () => [], type: [Array, Boolean] }) value!: number[] | null;

  @State("user", { namespace: "auth" }) user!: User;

  searchInput: null | string = null;
  tags: Tag[] = [];
  isLoading = false;

  get canAddNew(): boolean {
    return this.user.role === UserRole.ADMIN;
  }

  clearSearchInput(): void {
    this.searchInput = null;
  }

  handleOnInput(tags: number[]): void {
    this.$emit("input", tags);
  }

  handleOnKeydown(event: KeyboardEvent) {
    if (!this.canAddNew) {
      return;
    }

    if (event.code === "Enter") {
      this.saveTag();
    }
  }

  async saveTag() {
    try {
      this.isLoading = true;
      const newTag = await TagService.save({
        name: (this.searchInput as string).trim(),
      });
      this.tags.push(newTag);

      let newValue: number[] = [newTag.id as number];

      if (Array.isArray(this.value)) {
        newValue = newValue.concat(this.value);
      }

      this.handleOnInput(newValue);
      this.clearSearchInput();
    } finally {
      this.isLoading = false;
    }
  }

  async fetchTags(): Promise<void> {
    try {
      this.isLoading = true;
      this.tags = (await TagService.find()).content;
    } finally {
      this.isLoading = false;
    }
  }

  created() {
    this.fetchTags();
  }
}
