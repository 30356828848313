import ProjectBuyer, {
  BuyerStatStatus,
  BuyerStatResponse,
  UpdateBuyer,
} from "@/entity/ProjectBuyer";
import { TableQuery } from "@/components/data-table/types";
import { ListResponseEntity } from "@/services/types";
import Axios from "axios";
import filterQueryParams from "@/services/filterQueryParams";
import Contact, { getContactFullName } from "@/entity/Contact";
import { ALL_ITEMS_PER_PAGE } from "@/constants";
import ExpressionOfInterest, {
  BuyersExpressionOfInterest,
  CreateExpressionOfInterest,
} from "@/entity/ExpressionOfInterest";

export default class ProjectBuyersService {
  static async findOne(
    projectId: string,
    buyerId: number
  ): Promise<ProjectBuyer> {
    const response = await Axios.get(
      `/api/v2/projects/${projectId}/buyers/${buyerId}`
    );
    return response.data;
  }

  static async find(
    projectId: string | number,
    query: TableQuery
  ): Promise<ListResponseEntity<ProjectBuyer>> {
    /**
     * API Ignore the projectId in parameter and only uses the query params
     */
    const response = await Axios.get<ListResponseEntity<any>>(
      `/api/v2/projects/${projectId}/buyers`,
      {
        params: {
          ...filterQueryParams(query),
        },
      }
    );

    return response.data;
  }

  static async findAllBuyerContacts(
    projectId: string | number
  ): Promise<Contact[]> {
    const response = await this.find(projectId, {
      itemsPerPage: ALL_ITEMS_PER_PAGE,
    });
    return response.content
      .filter((buyer) => !!buyer.contact)
      .map((buyer) => ({
        ...(buyer.contact as Contact),
        fullName: getContactFullName(buyer.contact),
      }));
  }

  static async delete(
    projectId: string | number,
    buyerId: string | number
  ): Promise<void> {
    const response = await Axios.delete(
      `/api/v2/projects/${projectId}/buyers/${buyerId}`
    );
    return response.data;
  }

  static async saveList(
    projectId: string | number,
    contactIds: number[]
  ): Promise<ProjectBuyer[]> {
    const mappedContacts = contactIds.map((contactId) => ({
      contactId,
      projectId:
        typeof projectId === "string" ? parseInt(projectId) : projectId,
      isPriority: false,
    }));

    const response = await Axios.post(
      `/api/v2/projects/${projectId}/buyers/list`,
      mappedContacts
    );
    return response.data;
  }

  static async update(
    projectId: string | number,
    buyerId: string | number,
    buyer: UpdateBuyer
  ): Promise<void> {
    const response = await Axios.put(
      `/api/v2/projects/${projectId}/buyers/${buyerId}`,
      buyer
    );
    return response.data;
  }

  static async relevant(
    projectId: number | string,
    query: TableQuery
  ): Promise<Contact[]> {
    const response = await Axios.get(
      `/api/v2/projects/${projectId}/buyers/relevant`,
      {
        params: {
          ...filterQueryParams(query),
        },
      }
    );
    return response.data;
  }

  static async sendTeaser(
    projectId: number | string,
    buyers: ProjectBuyer[]
  ): Promise<void> {
    const payload = buyers.map((buyer) => ({
      buyerId: buyer.id,
      name: getContactFullName(buyer.contact),
      email: (buyer.contact as Contact).email,
    }));

    const response = await Axios.post(
      `/api/v2/projects/${projectId}/teaser/send`,
      {
        emails: payload,
      }
    );
    return response.data;
  }

  static async sendExpose(
    projectId: number | string,
    buyers: ProjectBuyer[]
  ): Promise<void> {
    const payload = buyers.map((buyer) => ({
      buyerId: buyer.id,
      name: getContactFullName(buyer.contact),
      email: (buyer.contact as Contact).email,
    }));

    const response = await Axios.post(
      `/api/v2/projects/${projectId}/expose/send`,
      {
        buyers: payload,
      }
    );
    return response.data;
  }

  static async sendExposeFollowup(
    projectId: number | string,
    buyers: ProjectBuyer[]
  ): Promise<void> {
    const payload = buyers.map((buyer) => ({
      buyerId: buyer.id,
      name: getContactFullName(buyer.contact),
      email: (buyer.contact as Contact).email,
    }));

    const response = await Axios.post(
      `/api/v2/projects/${projectId}/expose-followup/send`,
      {
        buyers: payload,
      }
    );
    return response.data;
  }

  static async sendExpressionOfInterest(
    projectId: number | string,
    buyers: ProjectBuyer[]
  ): Promise<{ successful: boolean }> {
    const payload = buyers.map((buyer) => ({
      buyerId: buyer.id,
      name: getContactFullName(buyer.contact),
      email: (buyer.contact as Contact).email,
    }));

    const response = await Axios.post(
      `/api/v2/projects/${projectId}/eoi/send`,
      {
        emails: payload,
      }
    );
    return response.data;
  }

  static async getExpressionOfInterestByBuyer(
    projectId: number,
    buyerId: number,
    version?: string
  ): Promise<ExpressionOfInterest> {
    const response = await Axios.get(
      `/api/v2/projects/${projectId}/buyers/${buyerId}/eoi`,
      {
        params: {
          version,
        },
      }
    );
    return response.data;
  }

  static async getExpressionOfInterestByBuyerV2(
    projectId: number,
    buyerId: number
  ): Promise<BuyersExpressionOfInterest> {
    const response = await Axios.get(
      `/api/v2/projects/${projectId}/buyers/${buyerId}/eoi-v2`
    );
    return response.data;
  }

  static async getAllBuyersExpressionsOfInterest(
    projectId: number,
    buyerId: number
  ): Promise<ExpressionOfInterest[]> {
    const response = await Axios.get(
      `/api/v2/projects/${projectId}/buyers/${buyerId}/eoi/all`
    );
    return response.data;
  }

  static async createExpressionOfInterest(
    projectId: number,
    buyerId: number,
    payload: CreateExpressionOfInterest
  ): Promise<ExpressionOfInterest> {
    const response = await Axios.post(
      `/api/v2/projects/${projectId}/buyers/${buyerId}/eoi`,
      payload
    );
    return response.data;
  }

  static async getExpressionOfInterestLink(
    projectId: number,
    buyerId: number,
    eoiId: number
  ): Promise<{ url: string }> {
    const response = await Axios.post(
      `/api/v2/projects/${projectId}/buyers/${buyerId}/eoi/${eoiId}/share`
    );
    return response.data;
  }

  static async getExpressionOfInterestLinkV2(
    projectId: number,
    buyerId: number
  ): Promise<{ url: string }> {
    const response = await Axios.post(
      `/api/v2/projects/${projectId}/buyers/${buyerId}/eoi-v2/share`
    );
    return response.data;
  }

  static async getBuyerStatsStatus(
    projectId: number
  ): Promise<BuyerStatStatus[]> {
    const response = await Axios.get(
      `/api/v2/projects/${projectId}/buyers/stats/status`
    );
    return response.data;
  }

  static async getBuyerStatsResponse(
    projectId: number
  ): Promise<BuyerStatResponse[]> {
    const response = await Axios.get(
      `/api/v2/projects/${projectId}/buyers/stats/response`
    );
    return response.data;
  }

  static async addOrEditExpressionOfInterestManually(
    projectId: number,
    buyerId: number,
    data: { questionId: number; value: string }[]
  ): Promise<BuyersExpressionOfInterest> {
    const response = await Axios.post(
      `/api/v2/projects/${projectId}/buyers/${buyerId}/eoi-v2`,
      data
    );
    return response.data;
  }

  static async downloadBuyerStatsReport(
    projectId: number,
    payload: {
      findings: string;
      nextSteps: string;
    }
  ): Promise<Blob> {
    const response = await Axios.post(
      `/api/v2/projects/${projectId}/buyers/stats/report`,
      payload,
      {
        responseType: "blob",
      }
    );
    return response.data;
  }

  static async findForBuyerStatsReport(
    projectId: number,
    token: string,
    query: TableQuery
  ): Promise<ListResponseEntity<ProjectBuyer>> {
    /**
     * API Ignore the projectId in parameter and only uses the query params
     */
    const response = await Axios.get<ListResponseEntity<any>>(
      `/api/v2/projects/${projectId}/buyers/report/${token}`,
      {
        params: {
          ...filterQueryParams(query),
        },
      }
    );

    return response.data;
  }
}
