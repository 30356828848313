




































































import { Component, Mixins, Prop } from "vue-property-decorator";
import TranslatedEnumListsMixin from "../../mixins/TranslatedEnumListsMixin";
import DataTable from "@/components/data-table/DataTable.vue";
import HasAttachmentCell from "@/components/HasAttachmentCell.vue";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import { ITableHeader } from "@/components/data-table/types";
import History, { HistoryTypes, tagIdsToNames } from "@/entity/History";
import User from "@/entity/User";
import Project from "@/entity/Project";
import Tag from "@/entity/Tag";

@Component({
  components: {
    DataTable,
    HasAttachmentCell,
    ActionButtonWithTooltip,
  },
})
export default class ContactHistoryDataTable extends Mixins(
  TranslatedEnumListsMixin
) {
  @Prop({ type: Array, required: true }) history!: History[];
  @Prop({ type: Boolean, required: true }) isLoading!: boolean;
  @Prop({ type: Number, required: true }) totalItems!: number;
  @Prop({ type: Function, required: true }) onOptionsChange!: () => void;
  @Prop({ type: Function, required: true }) addItemClicked!: () => void;
  @Prop({ type: Function, required: true }) onRowClick!: () => void;
  @Prop({ type: Function, required: true }) deleteHistory!: (
    historyId: number
  ) => void;
  @Prop({ type: Array, required: true }) authorOptions!: User[];
  @Prop({ type: Array, required: true }) projects!: Project[];
  @Prop({ type: Array, required: true }) tags!: Tag[];

  tagIdsToNames = tagIdsToNames;

  get headers(): ITableHeader[] {
    return [
      {
        text: this.$tc("date", 1),
        value: "historyDate",
        filterType: "date",
        width: "150px",
      },
      {
        text: this.$tc("author", 1),
        value: "creatorId",
        options: this.authorOptions,
        optionText: "displayName",
        optionValue: "id",
        filterType: "select",
        width: "200px",
      },
      {
        text: this.$tc("project", 1),
        value: "projectId",
        options: this.projects,
        optionText: "projectContact.name",
        optionValue: "id",
        filterType: "select",
        width: "200px",
      },
      {
        text: this.$tc("note", 1),
        value: "note",
        filterType: "text",
        width: "300px",
      },
      {
        text: this.$tc("tag", 2),
        value: "tags",
        options: this.tags,
        optionText: "name",
        optionValue: "id",
        filterType: "select",
        multiselect: true,
        width: "150px",
      },
      {
        text: this.$tc("type", 1),
        value: "eventType",
        options: this.translatedEnums.historyTypes,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("attachment", 1),
        value: "hasAttachments",
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        sortable: true,
        width: "150px",
      },
      {
        text: this.$tc("action", 2),
        value: "actions",
        sortable: false,
        width: "80px",
      },
    ];
  }

  enableEdit(item: History): boolean {
    return item.eventType === HistoryTypes.MANUAL;
  }

  getAuthorName(item: History): string {
    if (item.eventType !== HistoryTypes.BASELINE && !item.creatorId)
      return this.$tc("autoGenerated");
    return item.creator ? item.creator.displayName : "";
  }

  displayProjectName(item: History): string {
    return item.project?.projectContact?.name ?? "";
  }

  projectNavigationTo(item: History) {
    if (!item.projectId) {
      return;
    }

    return {
      name: "projectHistory",
      params: {
        projectId: item.projectId.toString(),
      },
    };
  }

  isHistoryEoi(item: History): boolean {
    if (
      item.eventType === HistoryTypes.EXPRESSION_OF_INTEREST_FILLED ||
      item.eventType === HistoryTypes.EXPRESSION_OF_INTEREST_CHANGED
    )
      return true;
    return false;
  }
}
