
















































































import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import History, { CreateHistory, HistoryTypes } from "@/entity/History";
import { State } from "vuex-class";
import User from "@/entity/User";
import HistoryFormFiles from "@/components/history-form/HistoryFormFiles.vue";
import Contact, { ContactTypeId, getContactFullName } from "@/entity/Contact";
import HistoryFormBasicInfo from "@/components/history-form/HistoryFormBasicInfo.vue";
import Project from "@/entity/Project";
import UserService from "@/services/UserService";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import ProjectsService from "@/services/ProjectsService";
import { ALL_ITEMS_PER_PAGE } from "@/constants";
import moment from "moment";
import BaseForm from "@/components/form/BaseForm";
import HistoryService from "@/services/HistoryService";
import HistoryFormFileUpload from "@/components/history-form/HistoryFormFileUpload.vue";
import ExpressionOfInterestFormFields from "@/components/common/ExpressionOfInterestFormFields.vue";
import ExpressionOfInterestFormFieldsV2 from "@/components/common/ExpressionOfInterestFormFieldsV2.vue";
import ExpressionOfInterestFiles from "@/components/common/ExpressionOfInterestFiles.vue";
import {
  ExpressionOfInterestFormQuestion,
  CreateExpressionOfInterest,
} from "@/entity/ExpressionOfInterest";
import BTFile from "@/entity/File";

@Component({
  components: {
    FormCard,
    HistoryFormFiles,
    HistoryFormBasicInfo,
    HistoryFormFileUpload,
    ExpressionOfInterestFormFieldsV2,
    ExpressionOfInterestFormFields,
    ExpressionOfInterestFiles,
  },
})
export default class HistoryForm extends Mixins(BaseForm) {
  @Ref("file-upload") fileUpload!: HistoryFormFileUpload;
  @Prop({ required: true, type: String }) formType!: string;
  @Prop({ type: Number, default: null }) projectId!: number | null;
  @Prop({ type: Number, default: null }) contactId!: number | null;
  @State("user", { namespace: "auth" }) loggedInUser!: User;

  entityIdLocal: null | number = null;
  authors: User[] = [];
  interested: Contact[] = [];
  projects: Project[] = [];
  data: CreateHistory = {
    projectId: null,
    contactId: null,
    creatorId: null,
    historyDate: moment().unix(),
    note: "",
    tags: [],
    eventType: HistoryTypes.MANUAL,
    meta: "",
  };

  showEoi = false;
  questions: ExpressionOfInterestFormQuestion[] = [];
  answers: Record<number, string> = {};
  attachedFiles: BTFile[] | null = null;
  dataForLegacyEoi: CreateExpressionOfInterest = {
    firstName: "",
    name: "",
    profileUrl: "",
    interestedNote: "",
    intentionsNote: "",
    overtakeNote: "",
    sellerRoleNote: "",
    ownFunds: "",
    bankFinancedAcquisitionExperience: "",
  };

  activeTab = 1;
  tabItems = [{ key: "New Version", name: "New Version" }];

  setData(data: History): void {
    this.data = {
      projectId: data.projectId,
      contactId: data.contactId,
      creatorId: data.creatorId ?? null,
      historyDate: data.historyDate,
      note: data.note,
      tags: data.tags,
      meta: data.meta ?? "",
      eventType: data.eventType,
    };
  }

  get isEditable(): boolean {
    return this.data.eventType === HistoryTypes.MANUAL;
  }

  get isEditingLocal() {
    return this.entityIdLocal !== null;
  }

  async fetchHistory(keepLoading = false): Promise<History> {
    try {
      this.isLoading = true;
      const history = await HistoryService.findOne(this.entityId as number);
      if (
        (history.eventType === HistoryTypes.EXPRESSION_OF_INTEREST_FILLED ||
          history.eventType === HistoryTypes.EXPRESSION_OF_INTEREST_CHANGED) &&
        history.meta &&
        history.projectId
      ) {
        const { buyerId } = JSON.parse(history.meta);
        await this.loadEoi(buyerId, history.projectId);
        this.showEoi = true;
      }
      this.setData(history);
      return history;
    } finally {
      if (!keepLoading) {
        this.isLoading = false;
      }
    }
  }

  onFileDrop(file: File): void {
    this.fileUpload.setFile(file);
  }

  async fetchProjects(): Promise<Project[]> {
    if (!this.contactId) {
      return [];
    }

    const projectIds =
      await ProjectsService.getProjectIdsWhereContactParticipating(
        this.contactId
      );
    const projectResponse = await ProjectsService.find({
      filterBy: {
        id: projectIds.toString(),
      },
      itemsPerPage: ALL_ITEMS_PER_PAGE,
    });
    return projectResponse.content;
  }

  async fetchAllBuyerContacts(): Promise<Contact[]> {
    if (!this.projectId) {
      return [];
    }
    return ProjectBuyersService.findAllBuyerContacts(this.projectId);
  }

  async loadForm(): Promise<void> {
    if (this.entityId !== null) {
      this.entityIdLocal = this.entityId;
      const { creator, project, contact } = await this.fetchHistory(true);

      if (creator !== null) {
        this.authors.push(creator);
      }

      if (contact !== null) {
        this.interested.push({
          ...contact,
          fullName: getContactFullName(contact),
        });
      }

      if (project !== null) {
        this.projects.push(project);
      }
    } else {
      const promises: any[] = [
        UserService.findAll(),
        this.fetchAllBuyerContacts(),
        this.fetchProjects(),
      ];
      [this.authors, this.interested, this.projects] = await Promise.all(
        promises
      );
    }

    this.isLoading = false;
  }

  async submitForm(): Promise<void> {
    try {
      if (this.isEditingLocal) {
        await HistoryService.update(this.entityId as number, this.data);
      } else {
        const response = await HistoryService.save(this.data);
        this.entityIdLocal = response.id as number;
      }

      await this.fileUpload.uploadFile(this.entityIdLocal);
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    }
  }

  async onFileUploaded(loadAttachments: () => Promise<void>) {
    await loadAttachments();
    this.$emit("fileSuccess");
  }

  async loadEoi(buyerId: number, projectId: number): Promise<void> {
    const resV2 = await ProjectBuyersService.getExpressionOfInterestByBuyerV2(
      projectId,
      buyerId as number
    );
    this.questions = resV2.questions.sort((a, b) => a.orderNum - b.orderNum);
    this.attachedFiles = resV2.file;
    if (resV2.answers) {
      this.answers = resV2.answers.reduce(
        (acc, item) => ({ ...acc, [item.questionId]: item.answer }),
        {}
      );
    } else {
      this.answers = resV2.questions.reduce(
        (acc, item) => ({ ...acc, [item.id]: "" }),
        {}
      );
    }
    if (!resV2.answers) {
      // Old Version
      const response =
        await ProjectBuyersService.getExpressionOfInterestByBuyer(
          projectId,
          buyerId as number
        );

      if (typeof response === "object") {
        this.dataForLegacyEoi = {
          firstName: response.firstName,
          name: response.name,
          profileUrl: response.profileUrl,
          interestedNote: response.interestedNote,
          intentionsNote: response.intentionsNote,
          overtakeNote: response.overtakeNote,
          sellerRoleNote: response.sellerRoleNote,
          ownFunds: response.ownFunds,
          bankFinancedAcquisitionExperience:
            response.bankFinancedAcquisitionExperience,
        };
        this.tabItems.push({ key: "Legacy", name: "Legacy" });
      }
    }
  }

  get contactTypeId() {
    return ContactTypeId.contact;
  }

  created() {
    this.entityIdLocal = this.entityId;

    if (this.entityIdLocal === null) {
      this.data.creatorId = this.loggedInUser.id as number;

      if (this.contactId) {
        this.data.contactId = this.contactId;
      }

      if (this.projectId) {
        this.data.projectId = this.projectId;
      }
    }

    this.loadForm();
  }
}
