



























import { Component, Vue, Prop } from "vue-property-decorator";
import FileManagerService from "@/services/FileManagerService";

@Component
export default class HistoryFormFileUpload extends Vue {
  @Prop({ type: Number }) entityId!: number | null;

  isFormValid = false;
  isUploading = false;
  file: File | null = null;

  setFile(file: File): void {
    this.file = file;
  }

  async uploadFile(entityId: number | null) {
    if (this.file === null || entityId === null) return;

    try {
      this.isUploading = true;

      const response = await FileManagerService.uploadHistoryFile(
        entityId,
        this.file as File
      );
      this.file = null;
      this.$emit("fileUploaded");
      return response;
    } catch (e) {
      if (e.response.data.message === "File size should not exceed 1 MB") {
        this.$snackbarError(this.$tc("fileSizeError"));
      } else if (e.response.data.message) {
        this.$snackbarError(this.$tc("storageUnavalibleError"));
      } else {
        this.$snackbarError(this.$tc("apiErrors.unableToSave"));
      }
    } finally {
      this.isUploading = false;
    }
  }

  get disableUploadButton(): boolean {
    return (
      this.entityId === null ||
      this.isUploading ||
      !this.file ||
      !this.isFormValid
    );
  }
}
