





















import { Vue, Component, Prop } from "vue-property-decorator";
import BTFile from "@/entity/File";
import Loader from "@/components/common/Loader.vue";

@Component({
  components: {
    Loader,
  },
})
export default class HistoryFormFileList extends Vue {
  @Prop({ default: () => [] }) value!: BTFile[];
  @Prop({ default: false, type: Boolean }) loading!: boolean;

  openFile(fileId: string): void {
    window.open(`${window.location.origin}/api/v2/files/${fileId}`, "_blank");
  }
}
