



















































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import BaseForm from "@/components/form/BaseForm";
import CurrencyInput from "@/components/form/input/CurrencyInput.vue";
import {
  ExpressionOfInterestFormQuestion,
  ExpressionOfInterestFormQuestionType,
  ExpressionOfInterestFormAnswerOption,
} from "@/entity/ExpressionOfInterest";
import RulesMixin from "@/mixins/RulesMixin";

@Component({
  components: {
    CurrencyInput,
  },
})
export default class EoiPreview extends Mixins<BaseForm<File>, RulesMixin>(
  BaseForm,
  RulesMixin
) {
  @Prop({ required: true, type: Array })
  questions!: ExpressionOfInterestFormQuestion[];
  @PropSync("answers", { type: Object, required: true })
  answersLocal!: Record<number, string>;
  @Prop({ required: true, type: Boolean }) disableEverything!: boolean;

  isFreeText(questionTypeId: number) {
    return questionTypeId == ExpressionOfInterestFormQuestionType.FREE_TEXT;
  }

  isFreeTextShort(questionTypeId: number) {
    return (
      questionTypeId == ExpressionOfInterestFormQuestionType.FREE_TEXT_SHORT
    );
  }

  isStandardizedAnswers(questionTypeId: number) {
    return (
      questionTypeId ==
      ExpressionOfInterestFormQuestionType.STANDARDIZED_ANSWERS
    );
  }

  isAmount(questionTypeId: number) {
    return questionTypeId == ExpressionOfInterestFormQuestionType.AMOUNT;
  }

  getQuestionType(questionTypeId: number) {
    if (questionTypeId === ExpressionOfInterestFormQuestionType.FREE_TEXT)
      return "Free Text";
    if (questionTypeId === ExpressionOfInterestFormQuestionType.AMOUNT)
      return "Amount";
    if (
      questionTypeId ===
      ExpressionOfInterestFormQuestionType.STANDARDIZED_ANSWERS
    )
      return "Standardized Answers";
    return "";
  }

  getAnswerOptionsAsArray(answerOptions: ExpressionOfInterestFormAnswerOption) {
    if (!answerOptions) return [];
    const array: { text: string; value: string }[] = [];
    Object.keys(answerOptions).forEach((key) => {
      const value = (answerOptions as any)[key];
      if (key.includes("answer") && value) {
        array.push(value);
      }
    });
    return array;
  }
}
