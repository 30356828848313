import { TableQuery } from "@/components/data-table/types";
import Tag, { CreateTag, UpdateTag } from "@/entity/Tag";
import Axios from "axios";
import filterQueryParams from "./filterQueryParams";
import { ListResponseEntity } from "./types";

export default class TagService {
  public static async find(
    query?: TableQuery,
    libraryRequest = false
  ): Promise<ListResponseEntity<Tag>> {
    const finalQuery = { ...query };
    if (!libraryRequest) {
      finalQuery.filterBy = { ...finalQuery.filterBy, isDeleted: false };
      finalQuery.sortBy = { ...finalQuery.sortBy, name: false };
      finalQuery.itemsPerPage = 10000;
    }
    const response = await Axios.get("/api/v2/tags", {
      params: {
        ...filterQueryParams(finalQuery),
      },
    });
    return response.data;
  }

  public static async save(data: { name: string }): Promise<Tag> {
    const response = await Axios.post("api/v2/tags", {
      ...data,
      description: "",
    });
    return response.data;
  }

  public static async activate(tagId: number): Promise<number> {
    const response = await Axios.patch(`api/v2/tags/${tagId}/activate`);
    return response.data;
  }

  public static async deactivate(tagId: number): Promise<number> {
    const response = await Axios.patch(`api/v2/tags/${tagId}/deactivate`);
    return response.data;
  }

  public static async findOne(tagId: number): Promise<Tag> {
    const response = await Axios.get(`api/v2/tags/${tagId}`);
    return response.data;
  }

  public static async create(data: CreateTag): Promise<Tag> {
    const response = await Axios.post("api/v2/tags", data);
    return response.data;
  }

  public static async update(tagId: number, data: UpdateTag): Promise<Tag> {
    const response = await Axios.patch(`api/v2/tags/${tagId}`, data);
    return response.data;
  }
}
