import { TableQuery } from "@/components/data-table/types";
import { ListResponseEntity } from "@/services/types";
import History, { CreateHistory, HistoryTypes } from "@/entity/History";
import Axios from "axios";
import filterQueryParams from "@/services/filterQueryParams";
import Contact from "@/entity/Contact";

export default class HistoryService {
  public static async find(
    query: TableQuery
  ): Promise<ListResponseEntity<History>> {
    const response = await Axios.get(`/api/v2/history`, {
      params: {
        ...filterQueryParams(query),
      },
    });
    return response.data;
  }

  public static async findOne(historyId: number | string): Promise<History> {
    const response = await Axios.get(`/api/v2/history/${historyId}`);
    return response.data;
  }

  public static async delete(historyId: number): Promise<void> {
    const response = await Axios.delete(`/api/v2/history/${historyId}`);
    return response.data;
  }

  public static async save(history: CreateHistory): Promise<History> {
    const response = await Axios.post(`/api/v2/history`, {
      projectId: history.projectId,
      creatorId: history.creatorId,
      contactId: history.contactId,
      historyDate: history.historyDate,
      tags: history.tags,
      note: history.note,
      eventType: HistoryTypes.MANUAL,
    });
    return response.data;
  }

  public static async update(
    historyId: number,
    history: CreateHistory
  ): Promise<void> {
    const response = await Axios.put(`/api/v2/history/${historyId}`, {
      projectId: history.projectId, // To remove when its removed from API
      creatorId: history.creatorId, // To remove when its removed from API
      contactId: history.contactId, // To remove when its removed from API
      historyDate: history.historyDate, // To remove when its removed from API
      eventType: HistoryTypes.MANUAL, // To remove when its removed from API
      tags: history.tags !== null ? history.tags : undefined, // Dirty fix for now because when null is sent then 500 error
      note: history.note,
    });
    return response.data;
  }

  public static async contactNames(query: TableQuery): Promise<Contact[]> {
    const response = await Axios.get(`/api/v2/history/contactNames`, {
      params: {
        ...filterQueryParams(query),
      },
    });
    return response.data;
  }
}
