





































import { Component, Mixins, Prop } from "vue-property-decorator";
import { TableQuery } from "@/components/data-table/types";
import UserService from "@/services/UserService";
import HistoryService from "@/services/HistoryService";
import User from "@/entity/User";
import History from "@/entity/History";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import DialogMixin from "@/mixins/DialogMixin";
import HistoryForm from "@/components/history-form/HistoryForm.vue";
import Project from "@/entity/Project";
import ProjectsService from "@/services/ProjectsService";
import { ALL_ITEMS_PER_PAGE } from "@/constants";
import TagService from "@/services/TagService";
import Tag from "@/entity/Tag";
import Contact, { ContactTypeId } from "@/entity/Contact";
import ContactHistoryDataTable from "@/components/data-tables/ContactHistoryDataTable.vue";
import Company from "@/entity/Company";

@Component({
  components: {
    HistoryForm,
    ContactHistoryDataTable,
  },
})
export default class ContactHistoryView extends Mixins(
  TranslatedEnumListsMixin,
  DialogMixin
) {
  @Prop({ type: Number }) historyId!: number | null;
  @Prop({ required: true, type: Object }) company!: Company;

  history: History[] = [];
  itemsLength = 0;
  authorOptions: User[] = [];
  isHistoryLoading = false;
  isHistoryDeleting = false;
  showSkeleton = true;
  options!: TableQuery;
  projects: Project[] = [];
  tags: Tag[] = [];

  async fetchHistory(): Promise<void> {
    try {
      this.isHistoryLoading = true;
      this.tags = (await TagService.find()).content;
      const options = Object.assign({}, this.options, {
        filterBy: {
          ...this.options.filterBy,
          contactId: this.company.id as number,
          companySide: true,
        },
      });

      const response = await HistoryService.find(options);
      this.history = response.content;
      this.itemsLength = response.totalItems;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isHistoryLoading = false;
    }
  }

  async deleteHistory(historyId: number): Promise<void> {
    try {
      this.isHistoryDeleting = true;

      const didConfirm = await this.$confirm(this.$tc("confirmations.delete"));

      if (!didConfirm) {
        return;
      }

      await HistoryService.delete(historyId);
      this.fetchHistory();
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToDelete"));
    } finally {
      this.isHistoryDeleting = false;
    }
  }

  async fetchProjectForFilter(): Promise<Project[]> {
    const projectIds =
      await ProjectsService.getProjectIdsWhereContactParticipating(
        this.company.id as number
      );
    const projectResponse = await ProjectsService.find({
      filterBy: {
        id: projectIds.toString(),
      },
      itemsPerPage: ALL_ITEMS_PER_PAGE,
    });
    return projectResponse.content;
  }

  onOptionsChange(options: TableQuery) {
    this.options = options;
    this.fetchHistory();
  }

  get routeName(): string {
    if (this.company.contactTypeId === ContactTypeId.contact) {
      return "contact";
    } else if (this.company.contactTypeId === ContactTypeId.company) {
      return "company";
    }

    throw new Error("Invalid contact type");
  }

  onHistoryFormSuccess(): void {
    this.$router.replace({
      name: `${this.routeName}History`,
    });
    this.closeDialog();
    this.fetchHistory();
  }

  onHistoryOpen(history: History) {
    this.$router.replace({
      name: `${this.routeName}HistoryDetail`,
      params: {
        historyId: (history.id as number).toString(),
      },
    });
    this.openDialog("historyForm", history.id);
  }

  onHistoryCancel() {
    this.$router.replace({
      name: `${this.routeName}History`,
    });
    this.closeDialog();
  }

  async created() {
    if (this.historyId) {
      this.openDialog("historyForm", this.historyId);
    }
    this.authorOptions = await UserService.findAll();
    this.projects = await this.fetchProjectForFilter();
    this.showSkeleton = false;
  }
}
