









































































import { Component, Prop, Mixins, PropSync } from "vue-property-decorator";
import HistoryTagsSelection from "@/components/history-form/HistoryTagsSelection.vue";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";
import moment from "moment";
import { formatUnixTimestamp } from "@/utils/date";
import Contact from "@/entity/Contact";
import Project from "@/entity/Project";
import User from "@/entity/User";
import RulesMixin from "@/mixins/RulesMixin";

@Component({
  components: {
    HistoryTagsSelection,
    DatePickerWithInput,
  },
})
export default class HistoryFormBasicInfo extends Mixins(RulesMixin) {
  @Prop({ required: true, type: String }) formType!: string;
  @Prop({ required: true, type: Boolean }) isEditing!: boolean;
  @Prop({ required: true, type: Boolean }) isEditable!: boolean;
  @Prop({ default: () => [], type: Array }) authors!: User[];
  @Prop({ default: () => [], type: Array }) projects!: Project[];
  @Prop({ default: () => [], type: Array }) interested!: Contact;

  @PropSync("contactId", { type: Number }) contactIdLocal!: number;
  @PropSync("projectId", { type: Number }) projectIdLocal!: number;
  @PropSync("creatorId", { type: Number }) creatorIdLocal!: number;
  @PropSync("note", { type: String }) noteLocal!: number;
  @PropSync("tags", { type: Array }) tagsLocal!: number[];
  @PropSync("historyDate", { type: Number }) historyDateLocal!: null | number;

  formatUnixTimestamp = formatUnixTimestamp;

  handleDateChange(date: string): void {
    this.historyDateLocal = moment(date).unix();
  }
}
