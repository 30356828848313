

















































import { Vue, Component, Prop } from "vue-property-decorator";
import HistoryFormFileUpload from "@/components/history-form/HistoryFormFileUpload.vue";
import HistoryFormFileList from "@/components/history-form/HistoryFormFileList.vue";
import BTFile from "@/entity/File";
import FileManagerService from "@/services/FileManagerService";

@Component({
  components: {
    HistoryFormFileUpload,
    HistoryFormFileList,
  },
})
export default class HistoryFormFiles extends Vue {
  @Prop({ default: false, type: Boolean }) showList!: boolean;
  @Prop({ type: Number }) entityId!: number | null;

  attachments: BTFile[] = [];
  showSkeleton = true;
  isLoadingAttachments = false;
  hover = false;
  file: File | null = null;

  dragover(event: any) {
    event.preventDefault();
    this.hover = true;
  }

  dragleave() {
    this.hover = false;
  }

  drop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer) {
      this.$emit("onFileDrop", event.dataTransfer.files[0]);
    }

    this.hover = false;
  }

  async loadAttachments(): Promise<void> {
    try {
      this.isLoadingAttachments = true;
      this.attachments = await FileManagerService.getHistoryFiles(
        this.entityId as number
      );
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isLoadingAttachments = false;
    }
  }

  async deleteFile(fileId: number): Promise<void> {
    try {
      this.isLoadingAttachments = true;
      await FileManagerService.deleteHistoryFile(fileId);
      this.attachments = await FileManagerService.getHistoryFiles(
        this.entityId as number
      );
      this.$emit("fileDeleted");
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isLoadingAttachments = false;
    }
  }

  async created(): Promise<void> {
    if (this.entityId !== null) {
      await this.loadAttachments();
    }

    this.showSkeleton = false;
  }
}
