<template functional>
  <div>
    <v-icon v-if="props.hasAttachments"> mdi-paperclip </v-icon>
    <span v-else>
      {{ parent.$tc("no", 1) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    hasAttachments: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
